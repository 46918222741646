window.handleRemoveButton = (event) => {
  const container = event.parentNode;
  const input = container.querySelector('input');
  if (input) {
    input.value = '';
  }
};

window.handleShowPassButton = (event) => {
  const container = event.parentNode;
  const input = container.querySelector('input');
  if (input) {
    if (input.getAttribute('type') === 'password') {
      input.setAttribute('type', 'string');
    } else {
      input.setAttribute('type', 'password');
    }
  }
};
