// eslint-disable-next-line max-len
// NOTE: blocks "Blend" dropdown on "No Blend" in "Raw Material" phase if user selects other "Fabric" than "Blend", else it allows to choose "Blend" value
window.changeBlendInput = (element) => {
  if (element !== null) {
    const blendInput = document.querySelector('#phase_rm_blend');
    let noBlendOption = blendInput.querySelector('option[value="no_blend"]');
    if (element.value !== '') {
      while (noBlendOption !== null) {
        blendInput.removeChild(noBlendOption);
        noBlendOption = blendInput.querySelector('option[value="no_blend"]');
      }
    }
    if (element.value === 'blend') {
      blendInput.disabled = false;
    } else if (element.value !== '') {
      noBlendOption = document.createElement('option');
      noBlendOption.value = 'no_blend';
      noBlendOption.innerHTML = 'No Blend';
      blendInput.appendChild(noBlendOption);
      blendInput.value = 'no_blend';
      const blendInputOptions = blendInput.querySelectorAll('option');
      blendInputOptions.forEach((option) => {
        option.removeAttribute('selected');
      });
      noBlendOption = blendInput.querySelector('option[value="no_blend"]');
      noBlendOption.setAttribute('selected', 'selected');
      blendInput.disabled = true;
    }
  }
};
