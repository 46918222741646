// NOTE: allows to manipulate dropdown "Size" values based on "Gender" in "General information" phase
window.changeFootwearSizes = (element) => {
  const genderSelect = element;
  const footwearSizeSelect = document.querySelector('#phase_footwear_size');
  const minManFootwearSize = 39;
  const maxManFootwearSize = 46;
  const minWomanFootwearSize = 35;
  const maxWomanFootwearSize = 41;
  const minChildFootwearSize = 18;
  const maxChildFootwearSize = 39;
  const showOptionsBasedOnValueRange = (select, min, max) => {
    const selectOptions = select.querySelectorAll('option');
    selectOptions.forEach((option) => {
      const optionValue = parseInt(option.text, 10);
      if (optionValue < min || optionValue > max) {
        option.style.display = 'none';
        option.disabled = true;
      } else {
        option.style.display = 'block';
        option.disabled = false;
      }
    });
    const optionValue = parseInt(selectOptions[select.selectedIndex].value, 10);
    if (optionValue < min || optionValue > max) {
      select.selectedIndex = 0;
    }
  };
  switch (genderSelect.value) {
    case 'man':
      showOptionsBasedOnValueRange(footwearSizeSelect, minManFootwearSize, maxManFootwearSize);
      break;
    case 'woman':
      showOptionsBasedOnValueRange(footwearSizeSelect, minWomanFootwearSize, maxWomanFootwearSize);
      break;
    case 'child':
      showOptionsBasedOnValueRange(footwearSizeSelect, minChildFootwearSize, maxChildFootwearSize);
      break;
    default:
      showOptionsBasedOnValueRange(footwearSizeSelect, minChildFootwearSize, maxManFootwearSize);
      break;
  }
};
// NOTE: allows to manipulate dropdown "Process Type" values based on "Footwear Type" in "General information" phase
window.changeFootwearProcess = (element) => {
  const footwearTypeSelect = element;
  const footwearTypeInput = footwearTypeSelect.value;

  const footwearProcessTypeSelect = document.querySelector('#phase_footwear_process_type');
  const processTypeOptions = footwearProcessTypeSelect.querySelectorAll('option');

  if (footwearTypeInput === 'shoe' || footwearTypeSelect.selectedIndex === 0) {
    processTypeOptions.forEach((option) => {
      option.style.display = 'block';
      option.disabled = false;
    });
  } else if (footwearTypeInput === 'boot' || footwearTypeInput === 'mid_boot') {
    const nonCementedProcessOptions = footwearProcessTypeSelect
      .querySelectorAll('option[value="vulcanized"], option[value="injected"]');
    nonCementedProcessOptions.forEach((option) => {
      if (footwearProcessTypeSelect.selectedIndex === option.index) {
        footwearProcessTypeSelect.selectedIndex = 0;
      }
      option.style.display = 'none';
      option.disabled = true;
    });
  }

  if (!window.footwearType) {
    window.footwearType = null;
    window.footwearTypeChanged = false;
  }
  if (window.footwearType !== footwearTypeInput
    && !(window.footwearType === 'boot' && footwearTypeInput === 'mid_boot')
    && !(window.footwearType === 'mid_boot' && footwearTypeInput === 'boot')) {
    window.footwearTypeChanged = true;
  }
  window.footwearType = footwearTypeInput;
};

window.changeFootwearProcessType = (element) => {
  if (!window.footwearProcessType) {
    window.footwearProcessType = null;
    window.footwearProcessTypeChanged = false;
  }
  if (window.footwearProcess !== element.value) {
    window.footwearProcessTypeChanged = true;
  }
  window.footwearProcessType = element.value;
};

// NOTE: allows to manipulate dropdown "Material" values based on "Category" in "Components' Material" phase
window.changeFootwearMaterials = (categorySelect, materialSelect) => {
  const syntheticMaterials = ['pu', 'pvc', 'eva', 'thermoplastic_rubber', 'rubber', 'abs'];
  const textileMaterials = ['cotton', 'linen', 'wool', 'polyester', 'nylon', 'viscose', 'fake_fur'];
  const leatherMaterials = ['pig', 'lamb', 'sheep', 'cow'];
  const metalMaterials = ['metal'];
  switch (categorySelect.value) {
    case 'synthetics':
      Array.from(materialSelect.options).forEach((option) => {
        if (syntheticMaterials.includes(option.value)) {
          option.style.display = 'block';
          option.disabled = false;
        } else {
          option.style.display = 'none';
          option.disabled = true;
        }
      });
      break;
    case 'textile':
      Array.from(materialSelect.options).forEach((option) => {
        if (textileMaterials.includes(option.value)) {
          option.style.display = 'block';
          option.disabled = false;
        } else {
          option.style.display = 'none';
          option.disabled = true;
        }
      });
      break;
    case 'leather':
      Array.from(materialSelect.options).forEach((option) => {
        if (leatherMaterials.includes(option.value)) {
          option.style.display = 'block';
          option.disabled = false;
        } else {
          option.style.display = 'none';
          option.disabled = true;
        }
      });
      break;
    case 'metal':
      Array.from(materialSelect.options).forEach((option) => {
        if (metalMaterials.includes(option.value)) {
          option.style.display = 'block';
          option.disabled = false;
        } else {
          option.style.display = 'none';
          option.disabled = true;
        }
      });
      break;
    default:
      break;
  }
  materialSelect.options[0].style.display = 'block';
};

// eslint-disable-next-line max-len
// NOTE: toggles visibility (allows showing and hiding) "Material" and "Country" dropdowns based on "Category" input value
window.toggleFootwearMaterialsCountriesVisibility = (element, name) => {
  const select = element;
  const optionalBlocks = document.querySelectorAll(`#optional-block-${name}`);

  // no defualt values in simple_form input and last option is 'None'
  optionalBlocks.forEach((optional) => {
    const optionalSelects = optional.querySelectorAll('select');
    const materialSelect = optional.querySelector("select[id$='material']");
    const optionalErrors = optional.querySelectorAll('span[class="error"]');
    if (select.selectedIndex === 0 || select.selectedIndex === (select.options.length - 1)) {
      optionalSelects.forEach((optionalSelect) => {
        optionalSelect.selectedIndex = 1;
      });
      optional.style.display = 'none';
    } else {
      optionalSelects.forEach((optionalSelect) => {
        optionalSelect.selectedIndex = 0;
      });
      optionalErrors.forEach((error) => {
        error.remove();
      });
      optional.style.display = 'block';
    }
    if (materialSelect) {
      window.changeFootwearMaterials(select, materialSelect);
    }
  });
};

window.changeFootwearParams = () => {
  const footwearGenderSelect = document.querySelector('#phase_footwear_gender');
  if (footwearGenderSelect !== null) {
    footwearGenderSelect.onchange();
  }
  const footwearTypeSelect = document.querySelector('#phase_footwear_type');
  if (footwearTypeSelect !== null) {
    footwearTypeSelect.onchange();
  }
  const cmuMaterialCategorySelects = document.querySelectorAll("[id^='phase_cmu_'][id$='_category']");
  cmuMaterialCategorySelects.forEach((categorySelect) => {
    const materialSelectId = categorySelect.id.replace('category', 'material');
    const materialSelect = document.querySelector(`#${materialSelectId}`);
    window.changeFootwearMaterials(categorySelect, materialSelect);
  });
};

// eslint-disable-next-line max-len
// NOTE: toggles visibility (allows showing and hiding) for accessories based on "Number of Accessories" in "Accessories Used" phase
window.toggleFootwearAccessoryXVisibility = (element) => {
  const numberOfAccessories = parseInt(element.value, 10);
  const optionalBlocks = document.querySelectorAll('div#optional-block');
  const additionalBlocks = document.querySelectorAll('div#additional_fields');
  if (numberOfAccessories) {
    for (let i = 0; i < numberOfAccessories; i += 1) {
      const typeSelect = optionalBlocks[i].querySelector('select[id*="phase_au_accessory"][id*="type"]');
      if (typeSelect.value.length !== 0 && optionalBlocks[i].style.display === 'none') {
        typeSelect.selectedIndex = 0;
        const detailsBlock = optionalBlocks[i].querySelector('div[id*="block-accessory"][id*="details"]');
        detailsBlock.style.display = 'none';
        additionalBlocks[i].style.display = 'none';
      }
      optionalBlocks[i].style.display = 'block';
    }
  } else {
    optionalBlocks.forEach((currentBlock) => {
      currentBlock.style.display = 'none';
      const detailsBlock = currentBlock.querySelector('div[id*="block-accessory"][id*="details"]');
      detailsBlock.style.display = 'none';
      const selectsInside = currentBlock.querySelectorAll('select');
      selectsInside.forEach((currentSelect) => {
        currentSelect.selectedIndex = 1;
      });
    });
  }

  const displayedOptionalBlocksLength = document.querySelectorAll("div[id='optional-block'][style*='block']").length;
  if (numberOfAccessories !== displayedOptionalBlocksLength) {
    for (let i = numberOfAccessories; i < optionalBlocks.length; i += 1) {
      additionalBlocks[i].style.display = 'none';
      optionalBlocks[i].style.display = 'none';
      const detailsBlock = optionalBlocks[i].querySelector('div[id*="block-accessory"][id*="details"]');
      detailsBlock.style.display = 'none';
      const selectsInside = optionalBlocks[i].querySelectorAll('select');
      selectsInside.forEach((currentSelect) => {
        currentSelect.selectedIndex = 1;
      });
    }
  }
};

// eslint-disable-next-line max-len
// NOTE: toggles visibility (allows showing and hiding) for accessories details based on "Accessory Type" in "Accessories Used" phase
window.toggleFootwearAccessoryXDetailsVisibility = (element, accessoryId) => {
  const accessoryType = element.value;
  const detailsBlock = document.querySelector(`[id='block-accessory-${accessoryId}-details']`);
  const detailsInput = document.querySelector(`[id='phase_au_accessory_${accessoryId}_details']`);
  const detailsInputOptions = detailsInput.querySelectorAll('option');

  const detailOptions = {
    metal_parts: ['large', 'medium', 'small'],
    zippers_no_boots: ['plastic', 'metal'],
    zippers_boots: ['plastic', 'metal'],
  };

  const options = [''].concat(accessoryType in detailOptions ? detailOptions[accessoryType] : []);

  detailsInputOptions.forEach((detailsOption) => {
    if (options.includes(detailsOption.value)) {
      detailsOption.style.display = 'block';
      detailsOption.disabled = false;
    } else {
      detailsOption.style.display = 'none';
      detailsOption.disabled = true;
    }
  });

  detailsInput.selectedIndex = options.length > 1 ? 0 : 1;
  detailsBlock.style.display = options.length > 1 ? 'block' : 'none';
};

window.fillFootwearAccessoryDetails = () => {
  const typeSelects = document
    .querySelectorAll("div[id='optional-block'][style*='block'] select[id*=phase_au_accessory][id*=type]");
  const detailsSelects = document
    .querySelectorAll("div[id='optional-block'][style*='block'] select[id*=phase_au_accessory][id*=details]");

  for (let i = 1; i <= typeSelects.length; i += 1) {
    const typeSelect = typeSelects[i - 1];
    const detailsSelectValue = detailsSelects[i - 1].value;
    if (typeSelect) {
      window.toggleFootwearAccessoryXDetailsVisibility(typeSelect, i);
      detailsSelects[i - 1].value = detailsSelectValue;
    }
  }
};

// eslint-disable-next-line max-len
// NOTE: cleans fields that are specific for "Footwear Type" after changing this field (and filling with 1st placeholder values to pass validations) in "Components' Material" phase
window.cleanFootwearSpecificFieldsAfterTypeChange = () => {
  const phaseOrder = document.querySelector("[id='phase_order']");
  const isPhase2 = phaseOrder !== null && phaseOrder.value === '2';

  if (isPhase2 && window.footwearTypeChanged) {
    let specificSelector = null;
    switch (window.footwearType) {
      case 'shoe':
        specificSelector = "[class*='shoe-input']";
        break;
      case 'boot':
      case 'mid_boot':
        specificSelector = "[class*='boot-mid-boot']";
        break;
      default:
        break;
    }
    if (specificSelector !== null) {
      const specificProcessBlocks = document.querySelectorAll(specificSelector);
      if (specificProcessBlocks !== null) {
        specificProcessBlocks.forEach((block) => {
          const optionalBlocks = block.querySelectorAll("[id*='optional-block']");
          optionalBlocks.forEach((optionalBlock) => {
            optionalBlock.style.display = 'none';
            optionalBlock.querySelector('select').selectedIndex = 0;
          });
          const categorySelects = block.querySelectorAll("[id*='category']");
          categorySelects.forEach((categorySelect) => {
            categorySelect.selectedIndex = 0;
          });
        });
      }
    }
    window.footwearTypeChanged = false;
  }
};

// eslint-disable-next-line max-len
// NOTE: cleans fields that are specific for "Process Type" after changing this field (and filling with 1st placeholder values to pass validations) in "Process Locations And Transportation" phase
window.cleanFootwearSpecificProcessFieldsAfterProcessTypeChange = () => {
  const phaseOrder = document.querySelector("[id='phase_order']");
  const isPhase5 = phaseOrder !== null && phaseOrder.value === '5';

  if (isPhase5 && window.footwearProcessTypeChanged) {
    let specificProcessSelector = null;
    switch (window.footwearProcessType) {
      case 'cemented':
        specificProcessSelector = "[id*='phase_ppu_lasting'], [id*='phase_ppu_outsole']";
        break;
      case 'vulcanized':
        specificProcessSelector = "[id*='phase_ppu_vulc']";
        break;
      case 'injected':
        specificProcessSelector = "[id*='phase_ppu_injecting']";
        break;
      default:
        break;
    }
    if (specificProcessSelector !== null) {
      const specificProcessFields = document.querySelectorAll(specificProcessSelector);
      if (specificProcessFields !== null) {
        specificProcessFields.forEach((input) => {
          if (input.nodeName === 'INPUT') {
            input.value = '0';
          } else {
            input.selectedIndex = 0;
          }
        });
      }
    }
    window.footwearProcessTypeChanged = false;
  }
};

// eslint-disable-next-line max-len
// NOTE: toggles visibility (allows showing and hiding blocks for footwear 'manual' calculator) for glue "Details" in "Chemicals Used" phase
window.toggleFootwearGlueDetailsVisibility = (element) => {
  const select = element;
  const optional = document.querySelector('#optional-block');
  const optionalSelects = optional.querySelectorAll('select');
  const optionalErrors = optional.querySelectorAll('span[class="error"]');
  if (select.selectedIndex === 0 || select.value === 'false') {
    optionalSelects.forEach((optionalSelect) => {
      optionalSelect.selectedIndex = 1;
    });
    optional.style.display = 'none';
  } else {
    optionalSelects.forEach((optionalSelect) => {
      optionalSelect.selectedIndex = 0;
    });
    optionalErrors.forEach((error) => {
      error.remove();
    });
    optional.style.display = 'block';
  }
};

// NOTE: removes "Ship" from international transportation type dropdowns for following list
window.removeShipInternationalTransportationTypeForMissingDistances = (processType) => {
  const missingShipDistances = [
    'africa_austria',
    'africa_cambodia',
    'africa_luxembourg',
    'australia_luxembourg',
    'australia_austria',
    'austria_africa',
    'austria_australia',
    'austria_belgium',
    'austria_denmark',
    'austria_egypt',
    'austria_finland',
    'austria_greece',
    'austria_ireland',
    'austria_luxembourg',
    'austria_new_zealand',
    'austria_norway',
    'austria_pakistan',
    'austria_poland',
    'austria_romania',
    'austria_serbia',
    'austria_sweden',
    'austria_switzerland',
    'austria_ukraine',
    'austria_usa',
    'belgium_austria',
    'belgium_luxembourg',
    'belgium_switzerland',
    'cambodia_egypt',
    'cambodia_pakistan',
    'cambodia_africa',
    'denmark_luxembourg',
    'denmark_austria',
    'denmark_switzerland',
    'egypt_luxembourg',
    'egypt_austria',
    'egypt_cambodia',
    'finland_austria',
    'finland_luxembourg',
    'greece_switzerland',
    'greece_austria',
    'greece_luxembourg',
    'honduras_cambodia',
    'ireland_austria',
    'ireland_luxembourg',
    'ireland_switzerland',
    'luxembourg_africa',
    'luxembourg_australia',
    'luxembourg_austria',
    'luxembourg_belgium',
    'luxembourg_denmark',
    'luxembourg_egypt',
    'luxembourg_finland',
    'luxembourg_greece',
    'luxembourg_ireland',
    'luxembourg_new_zealand',
    'luxembourg_norway',
    'luxembourg_pakistan',
    'luxembourg_poland',
    'luxembourg_romania',
    'luxembourg_serbia',
    'luxembourg_sweden',
    'luxembourg_ukraine',
    'luxembourg_usa',
    'luxembourg_switzerland',
    'luxembourg_china',
    'mexico_cambodia',
    'netherlands_cambodia',
    'new_zealand_luxembourg',
    'new_zealand_switzerland',
    'new_zealand_cambodia',
    'new_zealand_austria',
    'norway_austria',
    'norway_cambodia',
    'norway_luxembourg',
    'norway_switzerland',
    'pakistan_austria',
    'pakistan_cambodia',
    'pakistan_luxembourg',
    'poland_luxembourg',
    'poland_austria',
    'poland_switzerland',
    'romania_austria',
    'romania_cambodia',
    'romania_luxembourg',
    'romania_switzerland',
    'serbia_luxembourg',
    'serbia_switzerland',
    'serbia_austria',
    'sweden_austria',
    'sweden_luxembourg',
    'sweden_switzerland',
    'switzerland_austria',
    'switzerland_belgium',
    'switzerland_denmark',
    'switzerland_greece',
    'switzerland_ireland',
    'switzerland_luxembourg',
    'switzerland_new_zealand',
    'switzerland_norway',
    'switzerland_poland',
    'switzerland_romania',
    'switzerland_serbia',
    'switzerland_sweden',
    'switzerland_ukraine',
    'ukraine_austria',
    'ukraine_luxembourg',
    'ukraine_switzerland',
    'usa_austria',
    'usa_luxembourg',
  ];

  const originCountry = document.querySelector(`select[id$='${processType}_country_of_origin']`).value;
  const destinationCountry = document.querySelector(`select[id$='${processType}_country_of_destination']`).value;
  const transportationTypeSelect = document
    .querySelector(`select[id$='${processType}_international_transportation_type']`);
  const additionalBlock = document.querySelector(`div[class*='${processType}'][id='additional_fields']`);
  const shipOption = transportationTypeSelect.querySelector('option[value="ship"]');
  const originDestinationStr = `${originCountry}_${destinationCountry}`;
  if (missingShipDistances.includes(originDestinationStr)) {
    shipOption.style.display = 'none';
    shipOption.disabled = true;
    additionalBlock.style.display = 'none';
    if (transportationTypeSelect.value === 'ship') {
      transportationTypeSelect.selectedIndex = 0;
    }
  } else {
    shipOption.style.display = 'block';
    shipOption.disabled = false;
  }
};

// NOTE: allows user to select only "Generic" country for "Synthetics" category in "Components' Material" phase
window.toggleFootwearComponentsCountryVisibilityForSynthetics = (element) => {
  const categorySelect = element;
  const countrySelectId = categorySelect.id.replace('category', 'country');
  const countrySelect = document.querySelector(`select#${countrySelectId}`);
  const countrySelectOptions = countrySelect.querySelectorAll('option');
  if (categorySelect.value === 'synthetics') {
    countrySelectOptions.forEach((option) => {
      if (option.value !== 'generic' && option.value !== '') {
        option.style.display = 'none';
        option.disabled = true;
      }
    });
  } else {
    countrySelectOptions.forEach((option) => {
      option.style.display = 'block';
      option.disabled = false;
    });
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically display footwear emission values for specific phase based on input values, and calls functions from phases_controller.rb
window.fillFootwearComponentsMaterialAdditionalFields = (componentName) => {
  const categoryInput = document.querySelector(`select[id*=${componentName}_category]`).value;
  const materialInput = document.querySelector(`select[id*=${componentName}_material]`).value;
  const countryInput = document.querySelector(`select[id*=${componentName}_country]`).value;

  const emissionTextNode = document.getElementById(`${componentName}-emission`);
  if (categoryInput !== 'none' && categoryInput && materialInput && countryInput) {
    const productId = document.getElementById('product_id').value;
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        product_id: productId,
        component_name: componentName,
        category: categoryInput,
        material: materialInput,
        country: countryInput,
        activity_type: 'FILL_COMPONENTS_MATERIAL_EMISSION',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      emissionTextNode.style.display = 'inline-block';
      emissionTextNode.innerText = ` ${data.component_co2_emission} kg CO2e`;
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  } else {
    emissionTextNode.style.display = 'none';
    emissionTextNode.innerText = '';
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically display footwear emission values for specific phase based on input values, and calls functions from phases_controller.rb
window.fillFootwearAccessoriessUsedAdditionalFields = (accessoryNumber, productId) => {
  const accessoryTypeSelect = document.querySelector(`select[id*=accessory_${accessoryNumber}_type]`);
  const accessoryType = document.querySelector(`select[id*=accessory_${accessoryNumber}_type]`).value;
  const accessoryDetails = document.querySelector(`select[id*=accessory_${accessoryNumber}_details]`).value;

  const emissionTextNode = document.getElementById(`accessory-${accessoryNumber}-emission`);
  const labelTextNode = document.getElementById(`accessory-${accessoryNumber}-label`);

  const additionalBlocks = document.querySelectorAll('div#additional_fields');
  if (accessoryType && accessoryDetails) {
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        accessory_type: accessoryType,
        accessory_details: accessoryDetails,
        activity_type: 'FILL_ACCESSORIES_EMISSION',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      additionalBlocks[parseInt(accessoryNumber, 10) - 1].style.display = 'block';
      labelTextNode.innerText = accessoryTypeSelect[accessoryTypeSelect.selectedIndex].text;
      emissionTextNode.innerText = ` ${data.accessory_co2_emission}`;
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  } else {
    additionalBlocks[parseInt(accessoryNumber, 10) - 1].style.display = 'none';
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically display distance values for specific phase based on input values, and calls functions from phases_controller.rb
window.fillFootwearProcessLocationAndTransportationAdditionalFields = (processType, productId) => {
  const originCountry = document.querySelector(`select[id$='${processType}_country_of_origin']`).value;
  const productionCountry = document.querySelector(`select[id$='${processType}_country_of_destination']`).value;
  const transportationType = document.querySelector(`select[id$='${processType}_international_transportation_type']`)
    .value;

  const additionalBlock = document.querySelector(`div[class*='${processType}'][id='additional_fields']`);
  if (originCountry && productionCountry && transportationType && originCountry !== 'none'
    && productionCountry !== 'none' && transportationType !== 'none' && originCountry !== productionCountry) {
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        origin: originCountry,
        destination: productionCountry,
        mode: transportationType,
        activity_type: 'FILL_DISTANCE',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      const originCountryHumanText = originCountry.replace('_', ' ').replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
      const productionCountryHumanText = productionCountry.replace('_', ' ')
        .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
      const transportationTypeHumanText = transportationType.replace('_', ' ')
        .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
      const distanceLabel = document.querySelector(`label[id*=${processType}-international-distance-label]`);
      // eslint-disable-next-line max-len
      distanceLabel.innerText = `From ${originCountryHumanText} to ${productionCountryHumanText} by ${transportationTypeHumanText}`;
      const distanceValueNode = document.querySelector(`th[id*=${processType}-international-distance-value]`);
      distanceValueNode.innerText = data.distance;
      if (data.distance !== 0) {
        additionalBlock.style.display = 'block';
      } else {
        additionalBlock.style.display = 'none';
      }
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  } else {
    additionalBlock.style.display = 'none';
  }
};
