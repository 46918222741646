// eslint-disable-next-line max-len
// NOTE: allows to dynamically display denim emission values for specific phase based on input values, and calls functions from phases_controller.rb
window.fillDenimYarnAdditionalFields = () => {
  const countryInput = document.getElementById('phase_yarn_country_denim')
    ? document.getElementById('phase_yarn_country_denim').value.trim() : false;
  if (countryInput) {
    const productId = document.getElementById('product_id').value;
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        country: countryInput,
        product_id: productId,
        activity_type: 'FILL_YARN_EMISSION',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      document.getElementById('blowing-value').innerText = data.blowing;
      document.getElementById('carding-value').innerText = data.carding;
      document.getElementById('drawing-value').innerText = data.drawing;
      document.getElementById('roving-value').innerText = data.roving;
      document.getElementById('ring-spinning-value').innerText = data.ring_spinning;
      document.getElementById('winding-value').innerText = data.winding;
      document.getElementById('warping-value').innerText = data.warping;
      document.getElementById('dyeing-value').innerText = data.dyeing;
      document.getElementById('sizing-value').innerText = data.sizing;
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically display denim emission values for specific phase based on input values, and calls functions from phases_controller.rb
window.fillDenimFabricAdditionalFields = () => {
  const countryInput = document.getElementById('phase_fabric_country_denim')
    ? document.getElementById('phase_fabric_country_denim').value.trim() : false;
  if (countryInput) {
    const productId = document.getElementById('product_id').value;
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        country: countryInput,
        product_id: productId,
        activity_type: 'FILL_FABRIC_EMISSION',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      document.getElementById('weaving-value').innerText = data.weaving;
      document.getElementById('heat-setting-value').innerText = data.heat_setting;
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically display denim emission values for specific phase based on input values, and calls functions from phases_controller.rb
window.fillDenimGarmentsAdditionalFields = () => {
  const countryInput = document.getElementById('phase_gm_country')
    ? document.getElementById('phase_gm_country').value.trim() : false;
  if (countryInput) {
    const productId = document.getElementById('product_id').value;
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        country: countryInput,
        product_id: productId,
        activity_type: 'FILL_GARMENTS_EMISSION',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      document.getElementById('cutting-sewing-value').innerText = data.cutting_sewing;
      document.getElementById('laundry-value').innerText = data.laundry;
      document.getElementById('finishing-value').innerText = data.finishing;
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically display denim emission values for specific phase based on input values, and calls functions from phases_controller.rb
window.fillDenimWetAdditionalFields = () => {
  const countryInput = document.getElementById('phase_wet_country')
    ? document.getElementById('phase_wet_country').value.trim() : false;
  if (countryInput) {
    const productId = document.getElementById('product_id').value;
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        country: countryInput,
        product_id: productId,
        activity_type: 'FILL_WET_EMISSION',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      document.getElementById('wash-scouring-value').innerText = data.wash_scouring;
      document.getElementById('wash-bleaching-dyeing-value').innerText = data.wash_bleaching_dyeing;
      document.getElementById('wash-drying-finishing-value').innerText = data.wash_drying_finishing;
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  }
};
