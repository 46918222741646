// NOTE: file contains functions that allows upload cancelling for "File Import" functionality
/* eslint-disable import/no-useless-path-segments */
import deleteCrossIcon from '../../../app/assets/images/ic_close.svg';

/* eslint-enable import/no-useless-path-segments */

window.cancelUpload = (id, inputId, submitContainerId = null) => {
  const overlay = document.getElementById(submitContainerId);
  overlay.classList.remove('file-present');

  const dt = new DataTransfer();
  const input = document.getElementById(inputId);
  const { files } = input;
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    if (id !== i) {
      dt.items.add(file);
    }
    input.files = dt.files;
  }
  document.getElementById(`uploaded-file-${id}`).remove();
  const uploadedFiles = document.querySelectorAll('div[id*=\'uploaded-file\']');
  for (let i = 0; i < uploadedFiles.length; i += 1) {
    const uploadedFileId = uploadedFiles[i].id.replace('uploaded-file-', '');
    if (uploadedFileId > id) {
      uploadedFiles[i].id = `uploaded-file-${String(uploadedFileId - 1)}`;
      uploadedFiles[i].querySelector('button').onclick = () => { // eslint-disable-line no-loop-func
        cancelUpload(uploadedFileId - 1, inputId, submitContainerId); // eslint-disable-line no-undef
      };
    }
  }
};

const insertFileBody = (index, name, filetype, previouslyUploadedFile, visible, submitContainerId, inputId) => (
  /* eslint-disable max-len */
  `<div 
    id='uploaded-file-${index}' 
    class='drag-and-drop__file-instance${visible ? ' visible' : ''}${previouslyUploadedFile ? ' m-b-16' : ''}'>
    <i class="input-history__file-icon input-history__file-icon_aqua input-history__file-icon_background-gray bi bi-filetype-${filetype}"></i>
    <div>
        <p class="m-t-0 m-b-0">${name}</p>
        <button 
        type='button'
        onclick='cancelUpload(${index}, "${inputId}", ${submitContainerId ? '"'.concat(submitContainerId, '"') : null})'
        id='delete-upload-button-${index}'
        class='drag-and-drop__file-cancel-button'
        aria-label='Close'>
        <img class='drag-and-drop__delete-icon' src=${deleteCrossIcon}>
        </button>
    </div>
  </div>`
  /* eslint-enable max-len */
);

export function insertCancelableFile(input, submitContainerId) {
  const insertAfter = input;
  const file = input.files[0];
  insertAfter.insertAdjacentHTML('afterend', insertFileBody(
    0,
    file.name,
    file.name.replace(/^.+\./, ''),
    null,
    true,
    submitContainerId,
    input.id,
  ));
}

export function clearCancelableFiles() {
  const uploadedFiles = document.querySelectorAll('div[id*=\'uploaded-file\']');
  uploadedFiles.forEach((uploadedFile) => {
    uploadedFile.remove();
  });
}

export function updateInputState(input, submitContainerId) {
  const overlay = document.getElementById(submitContainerId);
  if (overlay) {
    if (input.files) {
      overlay.classList.add('file-present');
    } else {
      overlay.classList.remove('file-present');
    }
  }

  clearCancelableFiles(submitContainerId);
  insertCancelableFile(input, submitContainerId);
}
