// NOTE: Allows 'copy to clipboard' functionality for auth token
import ClipboardJS from 'clipboard';

$(document).ready(() => {
  const tokenClipboard = new ClipboardJS('*[class*="auth__button_clipboard"]', {
    container: document.getElementById('auth-token'),
  });

  tokenClipboard.on('success', (e) => {
    const { innerHTML } = e.trigger;
    e.trigger.innerHTML = `${(innerHTML.split('>')[0]).toString()}> Copied!`;
    e.trigger.style.pointerEvents = 'none';
    setTimeout(() => {
      e.trigger.innerHTML = innerHTML;
      e.trigger.style.pointerEvents = 'all';
    }, 1000);
    e.clearSelection();
  });
});
