// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'chartkick';
import * as ActiveStorage from '@rails/activestorage';

ActiveStorage.start();

// import 'stylesheets/application'

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('@popperjs/core');
require('bootstrap');
require('../src/common');
require('../src/t_shirt');
require('../src/denim');
require('../src/handbag');
require('../src/footwear');
require('../src/show_charts');
require('../src/dashboard');
require('../src/handle_input_buttons');
require('../src/dismiss_flash');
require('../src/clipboard');
require('../src/drag_and_drop');
require('../src/mass_input_process_refreshing');
