// NOTE: allows refreshing "Input files history" list
window.setMassInputProcessRefreshing = (userId) => {
  if (document.querySelector('[id^=mass_input_process_record_]')) {
    $.ajax({
      url: '/mass_input_processes/update_mass_input_processes',
      type: 'GET',
      data: {
        userId,
      },
    // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
    // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
    // eslint-disable-next-line no-console
      console.log('error');
    });
  }
};

// NOTE: allows refreshing "Input file" details
window.setMassInputProcessDetailsRefreshing = (massInputProcessId) => {
  if (document.querySelector('#mass_input_process_details')) {
    $.ajax({
      url: '/mass_input_processes/update_mass_input_process_details',
      type: 'GET',
      data: {
        mass_input_id: massInputProcessId,
      },
    // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
    // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
    // eslint-disable-next-line no-console
      console.log('error');
    });
  }
};
