// NOTE: file contains functions that implements 'drag and drop' functionality for "File Import"
import { updateInputState } from './cancellable_multi_upload';

window.onDrop = (event, submitContainerId = null) => {
  event.preventDefault();
  const dt = new DataTransfer();
  const input = event.target;
  const file = Array.from(event.dataTransfer.files)[0];
  dt.items.add(file);
  input.files = dt.files;
  updateInputState(input, submitContainerId);
};

window.changeInputState = (event, targetInput, submitContainerId = null) => {
  const input = targetInput !== null ? targetInput : event.target;
  updateInputState(input, submitContainerId);
};

window.emulateClick = (targetSelector) => {
  const targetElement = document.querySelector(targetSelector);
  targetElement.click();
};
