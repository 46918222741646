// NOTE: file contains common js functions for all 'manual' calculators

// NOTE: toggles visibility (allows showing and hiding blocks for 'manual' calculators)
window.toggleVisibility = (element) => {
  const select = element;
  const optional = document.querySelector('#optional-block');
  const optionalInputs = optional.querySelectorAll('input');
  const optionalSelects = optional.querySelectorAll('select');
  const optionalErrors = optional.querySelectorAll('span[class="error"]');
  if (select.selectedIndex === 0) {
    optionalInputs.forEach((input) => {
      input.value = '1';
    });
    optionalSelects.forEach((optionalSelect) => {
      optionalSelect.selectedIndex = 1;
    });
    optional.style.display = 'none';
  } else {
    optionalInputs.forEach((input) => {
      input.value = '';
    });
    optionalSelects.forEach((optionalSelect) => {
      optionalSelect.selectedIndex = 0;
    });
    optionalErrors.forEach((error) => {
      error.remove();
    });
    optional.style.display = 'block';
  }
};

// NOTE: toggles visibility (allows showing and hiding blocks for 'manual' calculators)
window.toggleVisibilityReversed = (element) => {
  const select = element;
  const optional = document.querySelector('#optional-block');
  const optionalInputs = optional.querySelectorAll('input');
  const optionalSelects = optional.querySelectorAll('select');
  const optionalErrors = optional.querySelectorAll('span[class="error"]');
  if (select.selectedIndex !== 0) {
    optionalInputs.forEach((input) => {
      input.value = '1';
    });
    optionalSelects.forEach((optionalSelect) => {
      optionalSelect.selectedIndex = 1;
    });
    optional.style.display = 'none';
  } else {
    optionalInputs.forEach((input) => {
      input.value = '';
    });
    optionalSelects.forEach((optionalSelect) => {
      optionalSelect.selectedIndex = 0;
    });
    optionalErrors.forEach((error) => {
      error.remove();
    });
    optional.style.display = 'block';
  }
};

// NOTE: fills hidden inputs with 1st value to pass validations
window.fillInputs = () => {
  const optionalBlocks = document.querySelectorAll(
    'div[id="optional-block"][style*="display: none"], div[id="optional-block"][style*="display:none"]',
  );
  optionalBlocks.forEach((optional) => {
    const optionalInputs = optional.querySelectorAll('input');
    const optionalSelects = optional.querySelectorAll('select');
    optionalInputs.forEach((input) => {
      input.value = '1';
    });
    optionalSelects.forEach((optionalSelect) => {
      optionalSelect.selectedIndex = 1;
    });
  });
};

// NOTE: removes decimals from numbers
window.removeDecimals = (element) => {
  element.value = Math.abs(parseInt(element.value, 10));
  element.value = element.value === '0' ? '1' : element.value;
};

// NOTE: removes decimals from numbers
window.removeAllDecimals = () => {
  const floatInputs = document.querySelectorAll(
    'input[type="number"][class*="numeric float"]:not(#phase_mc_strap_length)',
  );
  floatInputs.forEach((floatInput) => {
    floatInput.value = Math.abs(parseInt(floatInput.value, 10));
  });
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically fill weight for specific products based on input values, and calls functions from phases_controller.rb
window.fillWeight = (element, productId) => {
  // eslint-disable-next-line no-undef
  const productType = gon.product_type;
  if (productType === 't_shirt' || productType === 'denim') {
    element.value = Math.abs(parseFloat(element.value)).toFixed(1);
    element.value = element.value === '0' ? '' : element.value;

    const sizeInput = document.getElementById('phase_size').value;
    const genderInput = document.getElementById('phase_gender').value;

    if (Boolean(sizeInput.trim()) && Boolean(genderInput.trim())) {
      if (!element.value.trim()) {
        $.ajax({
          url: `/products/${productId}/phases/fill_fields`,
          type: 'PATCH',
          data: {
            gender: genderInput,
            size: sizeInput,
            activity_type: 'FILL_WEIGHT',
          },
          // eslint-disable-next-line no-unused-vars
        }).done((data, textStatus, jqXHR) => {
          element.value = data.weight;
          // eslint-disable-next-line no-unused-vars
        }).fail((jqXHR, textStatus, errorThrown) => {
          // eslint-disable-next-line no-console
          console.log('error');
        });
      }
    }
  } else if (productType === 'handbag') {
    element.value = Math.abs(parseFloat(element.value)).toFixed(1);
    element.value = element.value === '0' ? '' : element.value;

    const handbagTypeInput = document.getElementById('phase_handbag_type').value;
    const handbagMaterialInput = document.getElementById('phase_handbag_main_body_material').value;
    const handbagSizeInput = document.getElementById('phase_handbag_size').value;

    if (Boolean(handbagTypeInput.trim()) && Boolean(handbagMaterialInput.trim()) && Boolean(handbagSizeInput.trim())) {
      if (!element.value.trim()) {
        $.ajax({
          url: `/products/${productId}/phases/fill_fields`,
          type: 'PATCH',
          data: {
            type: handbagTypeInput,
            material: handbagMaterialInput,
            size: handbagSizeInput,
            activity_type: 'FILL_WEIGHT',
          },
          // eslint-disable-next-line no-unused-vars
        }).done((data, textStatus, jqXHR) => {
          element.value = data.weight;
          // eslint-disable-next-line no-unused-vars
        }).fail((jqXHR, textStatus, errorThrown) => {
          // eslint-disable-next-line no-console
          console.log('error');
        });
      }
    }
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically fill weight for specific products based on input values, and calls functions from phases_controller.rb
window.forceFillWeight = (productId) => {
  // eslint-disable-next-line no-undef
  const productType = gon.product_type;
  if (productType === 't_shirt' || productType === 'denim') {
    const sizeInput = document.getElementById('phase_size').value;
    const genderInput = document.getElementById('phase_gender').value;

    if (Boolean(sizeInput.trim()) && Boolean(genderInput.trim())) {
      $.ajax({
        url: `/products/${productId}/phases/fill_fields`,
        type: 'PATCH',
        data: {
          gender: genderInput,
          size: sizeInput,
          activity_type: 'FILL_WEIGHT',
        },
        // eslint-disable-next-line no-unused-vars
      }).done((data, textStatus, jqXHR) => {
        document.getElementById('phase_weight').value = data.weight;
        // eslint-disable-next-line no-unused-vars
      }).fail((jqXHR, textStatus, errorThrown) => {
        // eslint-disable-next-line no-console
        console.log('error');
      });
    }
  } else if (productType === 'handbag') {
    const handbagTypeInput = document.getElementById('phase_handbag_type').value;
    const handbagMaterialInput = document.getElementById('phase_handbag_main_body_material').value;
    const handbagSizeInput = document.getElementById('phase_handbag_size').value;

    if (Boolean(handbagTypeInput.trim()) && Boolean(handbagMaterialInput.trim()) && Boolean(handbagSizeInput.trim())) {
      $.ajax({
        url: `/products/${productId}/phases/fill_fields`,
        type: 'PATCH',
        data: {
          type: handbagTypeInput,
          material: handbagMaterialInput,
          size: handbagSizeInput,
          activity_type: 'FILL_WEIGHT',
        },
        // eslint-disable-next-line no-unused-vars
      }).done((data, textStatus, jqXHR) => {
        document.getElementById('phase_weight').value = data.weight;
        // eslint-disable-next-line no-unused-vars
      }).fail((jqXHR, textStatus, errorThrown) => {
        // eslint-disable-next-line no-console
        console.log('error');
      });
    }
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically fill distance for specific products bases on input values, and calls functions from phases_controller.rb
window.fillDistance = (element, productId) => {
  element.value = Math.abs(parseFloat(element.value)).toFixed(0);
  element.value = element.value === '0' ? '' : element.value;
  const originInput = document.querySelector('*[id*="country_of_origin"]').value;
  const destinationInput = document.querySelector('*[id*="country_of_destination"]').value;

  const internationalModeInput = document.querySelector('*[id*="international_transportation_mode"]').value;
  // const domesticModeInput = document.querySelector('*[id*="domestic_transportation_mode"]').value;

  const internationalDistanceInput = document.querySelector('*[id*="international_distance"]').value;
  // const domesticDistanceInput = document.querySelector('*[id*="domestic_distance"]').value;

  if (Boolean(originInput.trim()) && Boolean(destinationInput.trim()) && Boolean(internationalModeInput.trim())) {
    if (!internationalDistanceInput.trim()) {
      $.ajax({
        url: `/products/${productId}/phases/fill_fields`,
        type: 'PATCH',
        data: {
          origin: originInput,
          destination: destinationInput,
          mode: internationalModeInput,
          activity_type: 'FILL_DISTANCE',
        },
        // eslint-disable-next-line no-unused-vars
      }).done((data, textStatus, jqXHR) => {
        document.querySelector('*[id*="international_distance"]').value = data.distance === 0 ? '' : data.distance;
        // eslint-disable-next-line no-unused-vars
      }).fail((jqXHR, textStatus, errorThrown) => {
        // eslint-disable-next-line no-console
        console.log('error');
      });
    }
  }

  // if (Boolean(originInput.trim()) && Boolean(destinationInput.trim()) && Boolean(domesticModeInput.trim())) {
  //   if (!domesticDistanceInput.trim()) {
  //     $.ajax({
  //       url: '/fill_distance',
  //       type: 'PATCH',
  //       data: {
  //         origin: originInput,
  //         destination: destinationInput,
  //         mode: domesticModeInput,
  //       },
  //       // eslint-disable-next-line no-unused-vars
  //     }).done((data, textStatus, jqXHR) => {
  //       document.querySelector('*[id*="domestic_distance"]').value = data.distance;
  //       // eslint-disable-next-line no-unused-vars
  //     }).fail((jqXHR, textStatus, errorThrown) => {
  //       // eslint-disable-next-line no-console
  //       console.log('error');
  //     });
  //   }
  // }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically fill distance for specific products bases on input values, and calls functions from phases_controller.rb
window.forceFillDistance = (productId) => {
  const originInput = document.querySelector('*[id*="country_of_origin"]').value;
  const destinationInput = document.querySelector('*[id*="country_of_destination"]').value;

  const internationalModeInput = document.querySelector('*[id*="international_transportation_mode"]').value;
  // const domesticModeInput = document.querySelector('*[id*="domestic_transportation_mode"]').value;

  if (Boolean(originInput.trim()) && Boolean(destinationInput.trim()) && Boolean(internationalModeInput.trim())) {
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        origin: originInput,
        destination: destinationInput,
        mode: internationalModeInput,
        activity_type: 'FILL_DISTANCE',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      document.querySelector('*[id*="international_distance"]').value = data.distance === 0 ? '' : data.distance;
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  }

  // if (Boolean(originInput.trim()) && Boolean(destinationInput.trim()) && Boolean(domesticModeInput.trim())) {
  //   $.ajax({
  //     url: '/fill_distance',
  //     type: 'PATCH',
  //     data: {
  //       origin: originInput,
  //       destination: destinationInput,
  //       mode: domesticModeInput,
  //     },
  //     // eslint-disable-next-line no-unused-vars
  //   }).done((data, textStatus, jqXHR) => {
  //     document.querySelector('*[id*="domestic_distance"]').value = data.distance;
  //     // eslint-disable-next-line no-unused-vars
  //   }).fail((jqXHR, textStatus, errorThrown) => {
  //     // eslint-disable-next-line no-console
  //     console.log('error');
  //   });
  // }
};

// NOTE: formats distance and return absolute integer value of number
window.formatDistance = (element) => {
  element.value = Math.abs(parseFloat(element.value)).toFixed(0);
};

// NOTE: changes visibility of specific block, allows do show and hide elements
window.changeVisibility = (element) => {
  const select = element;
  const additionalFieldsBlock = document.querySelectorAll('#additional_fields');
  additionalFieldsBlock.forEach((block) => {
    if (select.selectedIndex === 0) {
      block.style.display = 'none';
    } else {
      block.style.display = 'block';
    }
  });
};
