// NOTE: rounds strap length for "Handbag Making & Components Fixing" phase
window.roundStrapLength = (element) => {
  element.value = Math.round(element.value * 10) / 10;
};

// NOTE: allows to manipulate dropdown "Material" values based on "Handbag Type" in "General information" phase
window.changeHandbagMaterials = (element) => {
  if (element !== null) {
    const materialInput = document.querySelector('#phase_handbag_main_body_material');
    const materialInputOptions = materialInput.querySelectorAll('option');
    const polyesterOption = materialInput.querySelector('option[value="polyester"]');

    materialInputOptions.forEach((option) => {
      option.style.display = 'block';
      option.disabled = false;
    });

    if (element.value === 'backpack') {
      if (materialInput.selectedIndex === polyesterOption.index) {
        materialInput.selectedIndex = 0;
      }
      polyesterOption.style.display = 'none';
      polyesterOption.disabled = true;
    } else if (element.value === 'mini_bag') {
      if (materialInput.selectedIndex === polyesterOption.index) {
        materialInput.selectedIndex = 0;
      }
      polyesterOption.style.display = 'none';
      polyesterOption.disabled = true;
    }
  }
};

// NOTE: allows to manipulate dropdown "Size" values based on "Handbag Type" in "General information" phase
window.changeHandbagSizes = (element) => {
  if (element !== null) {
    const sizeInput = document.querySelector('#phase_handbag_size');
    const sizeInputOptions = sizeInput.querySelectorAll('option');

    sizeInputOptions.forEach((option) => {
      option.style.display = 'block';
      option.disabled = false;
    });

    if (!sizeInput.value.includes(element.value)) {
      sizeInput.selectedIndex = 0;
    }

    if (element.selectedIndex !== 0) {
      sizeInputOptions.forEach((option) => {
        if (!option.value.includes(element.value)) {
          option.style.display = 'none';
          option.disabled = true;
        }
      });
      sizeInputOptions[0].style.display = 'block';
    }
  }
};

window.changeHandbagParams = (element) => {
  window.changeHandbagMaterials(element);
  window.changeHandbagSizes(element);
};

// eslint-disable-next-line max-len
// NOTE: toggles visibility (allows showing and hiding) for specific dropdowns in "Handbag Making & Components Fixing" phase
window.toggleHandbagMakingVisibility = (element, name) => {
  const select = element;
  const optional = document.querySelector(`#optional-block-${name}`);
  const optionalInputs = optional.querySelectorAll('input');
  const optionalSelects = optional.querySelectorAll('select');
  const optionalErrors = optional.querySelectorAll('span[class="error"]');
  if (select.selectedIndex === 0 || select.selectedIndex === (select.options.length - 1)) {
    optionalInputs.forEach((input) => {
      input.value = '15';
    });
    optionalSelects.forEach((optionalSelect) => {
      optionalSelect.selectedIndex = 1;
    });
    optional.style.display = 'none';
  } else {
    optionalInputs.forEach((input) => {
      input.value = '';
    });
    optionalSelects.forEach((optionalSelect) => {
      optionalSelect.selectedIndex = 0;
    });
    optionalErrors.forEach((error) => {
      error.remove();
    });
    optional.style.display = 'block';
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically display handbag emission values for specific phase based on input values, and calls functions from phases_controller.rb
window.fillHandbagMaterialAdditionalFields = () => {
  const countryInput = document.getElementById('phase_mb_country')
    ? document.getElementById('phase_mb_country').value.trim() : false;
  if (countryInput) {
    const productId = document.getElementById('product_id').value;
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        country: countryInput,
        product_id: productId,
        activity_type: 'FILL_MATERIAL_EMISSION',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      document.getElementById('rawhide-value').innerText = data.leather_rawhide;
      document.getElementById('chemical-value').innerText = data.leather_chemical;
      document.getElementById('processing-value').innerText = data.leather_processing;
      document.getElementById('neutralization-value').innerText = data.leather_neutralization;
      document.getElementById('polishing-value').innerText = data.leather_polishing;
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically display handbag emission values for specific phase based on input values, and calls functions from phases_controller.rb
window.fillHandbagPartialMakingAdditionalFields = () => {
  const countryInput = document.getElementById('phase_mc_country')
    ? document.getElementById('phase_mc_country').value.trim() : false;
  if (countryInput) {
    const productId = document.getElementById('product_id').value;
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        country: countryInput,
        product_id: productId,
        activity_type: 'FILL_MAKING_EMISSION',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      document.getElementById('cutting-and-trimming-value').innerText = data.mc_cutting_and_trimming;
      document.getElementById('glue-processing-value').innerText = data.mc_glue_processing;
      document.getElementById('heat-fixing-value').innerText = data.mc_heat_fixing;
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  }
};

// eslint-disable-next-line max-len
// NOTE: allows to dynamically display handbag emission values for specific phase based on input values, and calls functions from phases_controller.rb
window.fillHandbagFinalAssemblingAdditionalFields = () => {
  const countryInput = document.getElementById('phase_fa_country')
    ? document.getElementById('phase_fa_country').value.trim() : false;
  if (countryInput) {
    const productId = document.getElementById('product_id').value;
    $.ajax({
      url: `/products/${productId}/phases/fill_fields`,
      type: 'PATCH',
      data: {
        country: countryInput,
        product_id: productId,
        activity_type: 'FILL_ASSEMBLING_EMISSION',
      },
      // eslint-disable-next-line no-unused-vars
    }).done((data, textStatus, jqXHR) => {
      document.getElementById('sewing-value').innerText = data.fa_sewing;
      document.getElementById('processing-value').innerText = data.fa_processing;
      document.getElementById('finishing-value').innerText = data.fa_finishing;
      // eslint-disable-next-line no-unused-vars
    }).fail((jqXHR, textStatus, errorThrown) => {
      // eslint-disable-next-line no-console
      console.log('error');
    });
  }
};
